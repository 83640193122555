import React from 'react';
import PropTypes from 'prop-types';

const Section = ({ title, type, className, children }) => {
  let bgStyles;
  let titleStyles;
  switch (type) {
    case 'dark':
      bgStyles = 'bg-black';
      titleStyles = 'text-white';
      break;

    default:
      bgStyles = 'bg-grey-lightest';
      titleStyles = 'text-black';
      break;
  }

  return (
    <div className={`py-24 ${bgStyles} ${className}`}>
      <div className="container mx-auto">
        <div className={`text-3xl ${titleStyles} antialiased mb-8 text-center`}>
          {title}
        </div>
        {children}
      </div>
    </div>
  );
};
Section.propTypes = {
  type: PropTypes.oneOf(['dark']),
  title: PropTypes.string,
  className: PropTypes.string,
};
Section.defaultProps = {
  className: '',
};

export default Section;
