import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

import Makers from './Makers';

class MakersWithBio extends React.Component {
  static propTypes = {
    defaultMaker: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedMaker: this.props.defaultMaker,
    };
  }

  handleMakerClick(maker) {
    this.setState({ selectedMaker: maker });
  }

  render() {
    const { selectedMaker } = this.state;

    return (
      <StaticQuery
        query={graphql`
          query {
            samurai: file(relativePath: { eq: "makers/samurai2.jpg" }) {
              data: childImageSharp {
                fixed(width: 600, quality: 80) {
                  src
                }
              }
            }
            peters: file(relativePath: { eq: "makers/jon-peters.jpg" }) {
              data: childImageSharp {
                fixed(width: 600, quality: 80) {
                  src
                }
              }
            }
            diresta: file(relativePath: { eq: "makers/diresta.jpg" }) {
              data: childImageSharp {
                fixed(width: 600, quality: 80) {
                  src
                }
              }
            }
            johnHeisz: file(relativePath: { eq: "makers/john-heisz.jpg" }) {
              data: childImageSharp {
                fixed(width: 600, quality: 80) {
                  src
                }
              }
            }
            liamHoffman: file(relativePath: { eq: "makers/liam-hoffman.jpg" }) {
              data: childImageSharp {
                fixed(width: 600, quality: 80) {
                  src
                }
              }
            }
            frankHowarth: file(
              relativePath: { eq: "makers/frank-howarth.jpg" }
            ) {
              data: childImageSharp {
                fixed(width: 600, quality: 80) {
                  src
                }
              }
            }
            neilPaskin: file(relativePath: { eq: "makers/neil-paskin.jpg" }) {
              data: childImageSharp {
                fixed(width: 600, quality: 80) {
                  src
                }
              }
            }
            # april: file(relativePath: { eq: "makers/april-wilkerson-2.jpg" }) {
            #   data: childImageSharp {
            #     fixed(width: 600, quality: 80) {
            #       src
            #     }
            #   }
            # }
          }
        `}
        render={data => (
          <div>
            <Makers
              defaultMaker={selectedMaker}
              onMakerClick={maker => this.handleMakerClick(maker)}
            />
            {getBio(selectedMaker, data)}
          </div>
        )}
      />
    );
  }
}

const getBio = (maker, data) => {
  switch (maker) {
    case 'samurai':
      return <SamuraiBio imgSrc={data.samurai.data.fixed.src} />;
    case 'peters':
      return <JohnPetersBio imgSrc={data.peters.data.fixed.src} />;
    case 'diresta':
      return <DirestaBio imgSrc={data.diresta.data.fixed.src} />;
    case 'neil':
      return <NeilBio imgSrc={data.neilPaskin.data.fixed.src} />;
    case 'frank':
      return <FrankBio imgSrc={data.frankHowarth.data.fixed.src} />;
    case 'ibuildit':
      return <IbuilditBio imgSrc={data.johnHeisz.data.fixed.src} />;
    case 'liam':
      return <LiamBio imgSrc={data.liamHoffman.data.fixed.src} />;
    default:
      return null;
  }
};

const SamuraiBio = ({ imgSrc }) => (
  <div className="mt-12">
    <div className="md:flex mx-2 md:mx-0 mb-24">
      <div className="md:w-1/2 md:mr-24">
        <img
          src={imgSrc}
          className="rounded shadow w-full mb-4 hidden md:block"
          alt="Samurai Carpenter"
        />
      </div>
      <div className="md:w-1/2">
        <h2 className="text-xl text-grey-darkest mb-1">
          Jesse De Geest (Samurai Carpenter)
        </h2>
        <h5 className="text-grey-dark text-sm uppercase font-normal mb-8">
          Master Carpenter &amp; Craftsman
        </h5>

        <h6 className="text-lg text-grey-darkest mb-2">
          Why the Samurai Carpenter?
        </h6>
        <p>
          The name came about when a close family friend was watching me use my
          tools, with my Japanese saw hanging off my back. He thought that I
          looked like a Samurai… so he started calling me The Samurai Carpenter,
          and the Legend[ish] was born.
        </p>
        <h6 className="text-lg text-grey-darkest mb-2">
          Where did I learn woodworking?
        </h6>
        <p>
          I’ve learned on the job site, from other expert carpenters, books,
          basically whatever I can get my hands on… but more than anything, I
          have learned the most from messing up and from complete and utter
          failure.  Really the art of learning What Not To Do, has taught me
          more than anything else!
        </p>
        <h6 className="text-lg text-grey-darkest mb-2">
          Where should you start?
        </h6>
        <p>
          I would recommend that if you are interested in learning woodworking
          or simply increasing your skill, then start with a project that is
          just outside of your current skill set.  This will stretch you, you
          will learn from your mistakes, and if each time you take on a project
          that is just a little bit more challenging than the last, you will
          grow your craft until you can build anything you want.
        </p>
      </div>
    </div>
  </div>
);

const JohnPetersBio = ({ imgSrc = '' }) => (
  <div className="mt-12">
    <div className="md:flex mx-2 md:mx-0 mb-24">
      <div className="md:w-1/2 md:mr-24">
        <img
          src={imgSrc}
          className="rounded shadow w-full mb-4 hidden md:block"
          alt="John Peters"
        />
      </div>
      <div className="md:w-1/2">
        <h2 className="text-xl text-grey-darkest mb-1">Jon Peters</h2>
        <h5 className="text-sm uppercase text-grey-dark font-normal mb-8">
          Master Craftsman &amp; Artist
        </h5>

        <h6 className="text-lg text-grey-darkest mb-2">
          Inspiration for Creative Living
        </h6>
        <p>
          I’m a professional artist and craftsman specializing in hand made
          furniture and custom commissioned artwork.
        </p>
        <p>
          I attended Ramapo state College where I studied welded steel sculpture
          and life drawing, while working full time with a furniture designer /
          builder. I worked with the company for several years and continued to
          attend classes at Ramapo and Parsons School of design in NYC.
        </p>
        <p>
          In 1994 I started my art and design company and made original art and
          furniture for clients throughout NYC and New Jersey.
        </p>
        <p>
          With almost 25 years of experience, my hope is that you find my
          Maker’s Mob informative and inspiring!
        </p>
      </div>
    </div>
  </div>
);

const DirestaBio = ({ imgSrc = '' }) => (
  <div className="mt-12">
    <div className="md:flex mx-2 md:mx-0 mb-24">
      <div className="md:w-1/2 md:mr-24">
        <img
          src={imgSrc}
          className="rounded shadow w-full mb-4 hidden md:block"
          alt="Jimmy DiResta"
        />
      </div>
      <div className="md:w-1/2">
        <h2 className="text-xl text-grey-darkest mb-1">Jimmy DiResta</h2>
        <h5 className="text-sm uppercase text-grey-dark font-normal mb-8">
          Maker &amp; Artist
        </h5>

        <p>
          I make stuff for a living, what you see me do here is my Job. I have
          been using tools for over 40 years and have developed a comfort level
          in the shop through years of experience.  My goal is to educate,
          entertain and inspire.
        </p>

        <p>
          From blacksmithing, leatherwork, woodworking, antique machine
          restoration, barn-building, fashion design,  to cedar-strip canoes, I
          love to create.  I have been making things ever since I was able to
          hold a hammer and likely longer than that. I grew up working around,
          and alongside my dad, a Long Island handyman.  He put me an
          environment to keep experimenting  and learning, so my early education
          involved a wide array of problem solving using all manner of tools,
          skills and materials… and that is my philosophy in the shop; learn all
          you can about anything and everything so it becomes a part of your
          problem solving arsenal.
        </p>

        <p>
          In my Makers Mob learn the skills and techniques, that will increase
          your creative problem solving and take your confidence to the next
          level, so that you begin to build your dream projects.
        </p>
      </div>
    </div>
  </div>
);

const NeilBio = ({ imgSrc = '' }) => (
  <div className="mt-12">
    <div className="md:flex mx-2 md:mx-0 mb-24">
      <div className="md:w-1/2 md:mr-24">
        <img
          src={imgSrc}
          className="rounded shadow w-full mb-4 hidden md:block"
          alt="Neil Paskin"
        />
      </div>
      <div className="md:w-1/2">
        <h2 className="text-xl text-grey-darkest mb-1">Neil Paskin</h2>
        <h5 className="text-sm uppercase text-grey-dark font-normal mb-8">
          Maker. Creator. Artist.
        </h5>

        <p>
          Hi I’m Neil. I started writing this page and it felt a little long and
          stuffy, so I had a re-think. Here’s the quick version!
        </p>

        <ul class="text-grey-darker">
          <li class="mb-2">I’ve always had an interest in art.</li>
          <li class="mb-2">
            I used to work in the construction industry, that paid the bills.
          </li>
          <li class="mb-2">
            I became a Landscape Photographer turning a passionate hobby into a
            job.
          </li>
          <li class="mb-2">While doing all this I made stuff.</li>
          <li class="mb-2">
            Including framing the thousands of photos I sold around the world.
          </li>
          <li class="mb-2">
            This led to where I am now making videos of the projects I make!
          </li>
          <li class="mb-2">
            I’m no real expert at anything, I just have a go! And give it my
            best shot!
          </li>
          <li class="mb-2">I’m passionate about creating.</li>
          <li class="mb-2">I try and find time to paint and sketch.</li>
          <li class="mb-2">
            I’m based on the Sunshine Coast, Queensland, Australia
          </li>
          <li class="mb-2">
            I’m married, have two growing kids, two Jack Russell puppies, four
            goats and ducks.
          </li>
        </ul>
      </div>
    </div>
  </div>
);

const FrankBio = ({ imgSrc = '' }) => (
  <div className="mt-12">
    <div className="md:flex mx-2 md:mx-0 mb-24">
      <div className="md:w-1/2 md:mr-24">
        <img
          src={imgSrc}
          className="rounded shadow w-full mb-4 hidden md:block"
          alt="Frank Howarth"
        />
      </div>
      <div className="md:w-1/2">
        <h2 className="text-xl text-grey-darkest mb-1">Frank Howarth</h2>
        <h5 className="text-sm uppercase text-grey-dark font-normal mb-8">
          Architecture Expressed Through Woodworking
        </h5>

        <p>
          I grew up in Honolulu, developing a free spirit in the natural world.
          Educated on the East Coast, earning a bachelors of architecture degree
          at Cornell University in 1992, and a masters of architecture degree at
          Harvard University in 1997. I have been developing my creative
          interests in Portland, Oregon. My interests are in architecture at a
          small scale expressed through woodworking and film making.
        </p>
      </div>
    </div>
  </div>
);

const IbuilditBio = ({ imgSrc = '' }) => (
  <div className="mt-12">
    <div className="md:flex mx-2 md:mx-0 mb-24">
      <div className="md:w-1/2 md:mr-24">
        <img
          src={imgSrc}
          className="rounded shadow w-full mb-4 hidden md:block"
          alt="John Heisz"
        />
      </div>
      <div className="md:w-1/2">
        <h2 className="text-xl text-grey-darkest mb-1">John Heisz</h2>
        <h5 className="text-sm uppercase text-grey-dark font-normal mb-8">
          ibuildit.ca
        </h5>

        <p>
          Me and my shop are in Ontario, Canada. I'm a carpenter by trade and
          have worked in commercial construction since 1985.
        </p>
      </div>
    </div>
  </div>
);

const LiamBio = ({ imgSrc = '' }) => (
  <div className="mt-12">
    <div className="md:flex mx-2 md:mx-0 mb-24">
      <div className="md:w-1/2 md:mr-24">
        <img
          src={imgSrc}
          className="rounded shadow w-full mb-4 hidden md:block"
          alt="Liam Hoffman"
        />
      </div>
      <div className="md:w-1/2">
        <h2 className="text-xl text-grey-darkest mb-1">Liam Hoffman</h2>
        <h5 className="text-sm uppercase text-grey-dark font-normal mb-8">
          Blacksmith
        </h5>

        <p>
          Growing up in the rural Blue Ridge Mountains of North Carolina I was
          accustomed to playing outside in the woods and creeks, opening up my
          mind to creativity. I have always been very hands-on and creative. I
          would make paper dye with wild violets and mold bowls from clay dug
          out of the creek banks. My mother and her family are very artistic and
          creative with design. My father is a hard working farmer. I believe I
          have both these attributes, and that when these traits are combined
          they fit perfectly the description of a blacksmith: hard work mixed
          with creative spirit.
        </p>

        <p>
          I began blacksmithing in the fall of 2008 at the age of thirteen,
          starting out with nothing but a pit fire and a trailer hitch for an
          anvil. Over the last several years I’ve worked my way up by purchasing
          new equipment one machine at a time and learning about the craft and
          science of moving steel. My parents have been extremely supportive in
          helping me pursue my passion. They gave me a push start by building a
          small brake drum forge for my fourteenth birthday. My dad took on the
          project of building my small 200 square foot shop over a two-year
          period. Once this foundation was established, I had my business well
          under way before graduating from high school.
        </p>
      </div>
    </div>
  </div>
);

// const AprilBio = ({ imgSrc = '' }) => (
//   <div className="mt-12">
//     <div className="md:flex mx-2 md:mx-0 mb-24">
//       <div className="md:w-1/2 md:mr-24">
//         <img
//           src={imgSrc}
//           className="rounded shadow w-full mb-4 hidden md:block"
//           alt="April Wilkerson"
//         />
//       </div>
//       <div className="md:w-1/2">
//         <h2 className="text-xl text-grey-darkest mb-1">April Wilkerson</h2>
//         <h5 className="text-sm uppercase text-grey-dark font-normal mb-8">
//           Maker &amp; Serial DIY'er
//         </h5>

//         <div className="mb-4 text-lg font-bold">Coming Spring 2019</div>

//         <p>You can call me a Maker, a DIYer, a Woodworker or Metalworker. But regardless of the label, I make stuff that I want/need instead of buying it or hiring it out. It’s my goal to be able to look around my home and have a hard time finding something I didn’t make with my own two hands. Of course, I still have a ways to go.</p>

//         <p>When I started blogging in February of 2013 I’d never used a power tool before. I didn’t grow up working on things nor did I take a shop class in school. But when I couldn’t afford to buy the things I wanted for around the house, I decided to try my hand at making them and dove headfirst into building. Now years later,  I still much prefer to build the things I want and need.</p>

//         <p>Shortly after I started making things, I started putting together written tutorials on my website so that I could pass along information to anybody else that was interested. One thing led to another and a year later I started making video tutorials as well! I put together free tutorials on anything and everything I do. I stick to building the things I want or need in my life but I bring a camera along because sharing the information on how I completed a project has become just as big of an obsession as doing the actual projects.</p>

//         <p>I hope you are able to find a project here that inspires you to pick up a tool you didn’t think you could use and build something you didn’t think you could build. You absolutely can. Don’t be afraid just because you don’t know how (yet). If you have the motivation to build something, take it one step at a time. Even if it takes you a week to build a box, you should still build that box.</p>
//       </div>
//     </div>
//   </div>
// );

export default MakersWithBio;
