import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';

import Logo from '../Logo';

// TODO: Depracate this component. Only the plans page should show the price.
const Pricebox = ({ hidePrice, maker }) => {
  if (process.env.NODE_ENV === 'development') {
    console.warn(
      'The Pricebox component is now deprecated. Only the plans page should show the price'
    );
  }

  return (
    <div className="flex-1 rounded shadow-md border border-grey-darkest bg-black hover:border-grey-dark p-6 text-grey-light">
      <div className={`w-full text-center ${hidePrice ? 'mb-0' : 'mb-8'}`}>
        <Logo className="w-64 mb-1" />
        <div className="text-grey-dark">Woodworking School &amp; Community</div>
      </div>

      {/*!hidePrice && (
        <div className="relative mx-16">
          <div className="uppercase text-sm tracking-wide mb-2 text-green-dark">
            only
          </div>
          <div className="flex">
            <div className="text-md">$</div>
            <div
              className="tracking-tight m-0 leading-none -mt-2"
              style={{ fontSize: '5rem' }}
            >
              19.
            </div>
            <div className="text-4xl m-0 leading-none -ml-2">95</div>
            <div className="flex items-end">
              <div className="text-1xl text-grey-darker mb-2">per month</div>
            </div>
          </div>
        </div>
      )*/}

      <button
        className="w-full bg-green hover:bg-green-light active:bg-green-dark border border-green appearance-none rounded mt-8 p-4 text-white uppercase antialiased text-lg font-bold tracking-wide"
        onClick={e => navigate(`/plans?ref=${maker}`)}
      >
        Join Now
      </button>
      <div className="mt-2 text-sm text-grey-darker text-center">
        30 day Money Back Guarantee. Cancel Anytime.
      </div>
    </div>
  );
};
Pricebox.propTypes = {
  hidePrice: PropTypes.bool,
  maker: PropTypes.string,
};
Pricebox.defaultProps = {
  hidePrice: false,
};

export default Pricebox;
