import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

const CommunityImgs = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          community: allFile(
            filter: { relativePath: { glob: "community/*" } }
          ) {
            edges {
              node {
                childImageSharp {
                  fixed(width: 300, toFormat: WEBP) {
                    originalName
                    src
                  }
                }
              }
            }
          }
          communityJpg: allFile(
            filter: { relativePath: { glob: "community/*" } }
          ) {
            edges {
              node {
                childImageSharp {
                  fixed(width: 300, toFormat: JPG, quality: 70) {
                    originalName
                    src
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const communityImgs = data.community.edges
          .map(e => e.node.childImageSharp)
          .reduce((acc, i) => {
            acc[i.fixed.originalName.split('.')[0]] = i.fixed.src;
            return acc;
          }, {});
        const communityJpgImgs = data.communityJpg.edges
          .map(e => e.node.childImageSharp)
          .reduce((acc, i) => {
            acc[i.fixed.originalName.split('.')[0]] = i.fixed.src;
            return acc;
          }, {});

        return (
          <React.Fragment>
            <div
              className="h-48 -mb-32 mt-24 flex mx-2"
              style={{ height: 240 }}
            >
              <div
                className="md:shadow-lg"
                style={{ transform: 'rotate(2deg)' }}
              >
                <picture>
                  <source srcSet={communityImgs.com1} type="image/webp" />
                  <source srcSet={communityJpgImgs.com1} type="image/jpeg" />
                  <img src={communityJpgImgs.com1} alt="members work" />
                </picture>
              </div>
              <div
                className="md:shadow-lg -ml-8 -mt-2"
                style={{ transform: 'rotate(-2deg)' }}
              >
                <picture>
                  <source srcSet={communityImgs.com2} type="image/webp" />
                  <source srcSet={communityJpgImgs.com2} type="image/jpeg" />
                  <img src={communityImgs.com2} alt="members work" />
                </picture>
              </div>
              <div
                className="hidden md:block  md:shadow-lg"
                style={{ transform: 'rotate(3deg)' }}
              >
                <picture>
                  <source srcSet={communityImgs.com3} type="image/webp" />
                  <source srcSet={communityJpgImgs.com3} type="image/jpeg" />
                  <img src={communityImgs.com3} alt="members work" />
                </picture>
              </div>
              <div
                className="hidden md:block md:shadow-lg -ml-4 -mt-2"
                style={{ transform: 'rotate(-1deg)' }}
              >
                <picture>
                  <source srcSet={communityImgs.com4} type="image/webp" />
                  <source srcSet={communityJpgImgs.com4} type="image/jpeg" />
                  <img src={communityImgs.com4} alt="members work" />
                </picture>
              </div>
              <div
                className="hidden md:block  md:shadow-lg -ml-4 -mt-2 mr-2 md:mr-0"
                style={{ transform: 'rotate(4deg)' }}
              >
                <picture>
                  <source srcSet={communityImgs.com5} type="image/webp" />
                  <source srcSet={communityJpgImgs.com5} type="image/jpeg" />
                  <img src={communityImgs.com5} alt="members work" />
                </picture>
              </div>
            </div>

            <div
              className="flex h-48 -mb-24 mt-24 mx-2"
              style={{ height: 240 }}
            >
              <div
                className="md:shadow-lg"
                style={{ transform: 'rotate(-3deg)' }}
              >
                <picture>
                  <source srcSet={communityImgs.com11} type="image/webp" />
                  <source srcSet={communityJpgImgs.com11} type="image/jpeg" />
                  <img src={communityJpgImgs.com11} alt="members work" />
                </picture>
              </div>
              <div
                className="md:shadow-lg -ml-8 -mt-2"
                style={{ transform: 'rotate(2deg)' }}
              >
                <picture>
                  <source srcSet={communityImgs.com7} type="image/webp" />
                  <source srcSet={communityJpgImgs.com7} type="image/jpeg" />
                  <img src={communityImgs.com7} alt="members work" />
                </picture>
              </div>
              <div
                className="hidden md:block md:shadow-lg"
                style={{ transform: 'rotate(-4deg)' }}
              >
                <picture>
                  <source srcSet={communityImgs.com8} type="image/webp" />
                  <source srcSet={communityJpgImgs.com8} type="image/jpeg" />
                  <img src={communityImgs.com8} alt="members work" />
                </picture>
              </div>
              <div
                className="hidden md:block md:shadow-lg -ml-4 -mt-2"
                style={{ transform: 'rotate(2deg)' }}
              >
                <picture>
                  <source srcSet={communityImgs.com9} type="image/webp" />
                  <source srcSet={communityJpgImgs.com9} type="image/jpeg" />
                  <img src={communityImgs.com9} alt="members work" />
                </picture>
              </div>
              <div
                className="hidden md:block md:shadow-lg -ml-4 -mt-2 mr-2 md:mr-0"
                style={{ transform: 'rotate(-3deg)' }}
              >
                <picture>
                  <source srcSet={communityImgs.com10} type="image/webp" />
                  <source srcSet={communityJpgImgs.com10} type="image/jpeg" />
                  <img src={communityImgs.com10} alt="members work" />
                </picture>
              </div>
            </div>
          </React.Fragment>
        );
      }}
    />
  );
};

export default CommunityImgs;
