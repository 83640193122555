import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

const CommunityImgs = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          plansCut: file(relativePath: { eq: "plans/plan_cuts.png" }) {
            data: childImageSharp {
              fixed(width: 300, quality: 30) {
                src
              }
            }
          }
          plansWork: file(relativePath: { eq: "plans/plan_workbench.png" }) {
            data: childImageSharp {
              fixed(width: 300, quality: 100) {
                src
              }
            }
          }
          plansFoot: file(relativePath: { eq: "plans/plan_foot.png" }) {
            data: childImageSharp {
              fixed(width: 300, quality: 30) {
                src
              }
            }
          }
        }
      `}
      render={data => {
        const plansCut = data.plansCut.data.fixed.src;
        const plansWork = data.plansWork.data.fixed.src;
        const plansFoot = data.plansFoot.data.fixed.src;

        return (
          <div className="md:flex mx-4 md:mx-0 pt-12">
            <div className="md:w-1/2 mx-4 md:mx-0 md:mr-16 mb-24">
              <h6 className="text-lg text-grey-darkest mb-2">
                For the Beginner
              </h6>
              <div className="text-grey-darker mx-4 md:mx-auto leading-normal mb-6">
                If you are just starting out and completely new to woodworking,
                Our entry level tutorials and plans will get you started and
                well on your way
              </div>
              <h6 className="text-lg text-grey-darkest mb-2">
                For the Hobbyist
              </h6>
              <div className="text-grey-darker mx-4 md:mx-auto leading-normal mb-6">
                If you are a weekend warrior and love making the chips fly,
                inside you will get access to many videos and plans that will
                take your woodworking to the next level
              </div>
              <h6 className="text-lg text-grey-darkest mb-2">
                For the Craftsman
              </h6>
              <div className="text-grey-darker mx-4 md:mx-auto leading-normal mb-6">
                For the Craftsman who is looking to be a part of a community
                that views woodworking as an art form - you are in the right
                place.
              </div>
            </div>
            <div className="md:w-1/2 mb-96">
              <div className="relative">
                <div className="relative hidden md:block">
                  <img
                    src={plansCut}
                    alt="Cut list"
                    className="absolute pin-t pin-r shadow-md w-64 planAniRight"
                  />
                  <img
                    src={plansFoot}
                    alt="Workbench Foot Dimensions"
                    className="absolute pin-t pin-l shadow-md w-64 planAniLeft"
                  />
                  <img
                    src={plansWork}
                    alt="Workbench Overview"
                    className="absolute pin-t shadow-md -mt-8 planAniMain"
                    style={{ left: '22%', width: '20rem' }}
                  />
                </div>
                <div className="relative md:hidden">
                  <img
                    src={plansCut}
                    alt="Cut list"
                    className="absolute pin-t pin-r shadow-md w-64 mr-4"
                    style={{ transform: 'rotate(5deg)' }}
                  />
                  <img
                    src={plansFoot}
                    alt="Workbench Foot Dimensions"
                    className="absolute pin-t pin-l shadow-md w-64 ml-4"
                    style={{ transform: 'rotate(-5deg)' }}
                  />
                  <img
                    src={plansWork}
                    alt="Workbench Overview"
                    className="absolute pin-t shadow-md w-64 -mt-2"
                    style={{ left: '15%' }}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      }}
    />
  );
};

export default CommunityImgs;
