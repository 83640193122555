import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Testimonial = ({ source, img, name, loc, children, className }) => {
  return (
    <div
      className={`rounded bg-white p-4 pb-8 shadow-md flex-1 ${className} relative`}
    >
      <div className="flex mb-4 items-center">
        <div className="rounded-full w-12 h-12 bg-grey-light mr-4 overflow-hidden">
          <img src={img} alt="Avatar" />
        </div>
        <div className="">
          <div className="text-grey-darkest text-md font-bold">{name}</div>
          {loc && <div className="text-grey-dark uppercase text-xs">{loc}</div>}
        </div>
      </div>
      <div className="leading-tight mx-2 text-grey-darkest">{children}</div>

      <FontAwesomeIcon
        icon="quote-right"
        className="absolute pin-b pin-r mr-4 mb-4 text-blue-lighter"
      />
    </div>
  );
};

export default Testimonial;
