import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Totals = ({ className }) => {
  return (
    <div className={className}>
      <div
        className={`container mx-auto text-center uppercase tracking-wide font-bold pb-24 text-grey-darkest md:flex`}
      >
        <div className="md:w-1/3 mb-8 md:mb-0">
          <div className="bg-green rounded-full w-16 h-16 m-auto mb-4 flex items-center justify-center">
            <FontAwesomeIcon icon="lightbulb" className="text-3xl text-white" />
          </div>
          <div>69+ Exclusive Courses</div>
        </div>
        <div className="md:w-1/3 mb-8 md:mb-0">
          <div className="bg-green rounded-full w-16 h-16 m-auto mb-4 flex items-center justify-center">
            <FontAwesomeIcon
              icon="chalkboard-teacher"
              className="text-3xl text-white"
            />
          </div>
          <div>500+ Video Lessons</div>
        </div>
        <div className="md:w-1/3 mb-8 md:mb-0">
          <div className="bg-green rounded-full w-16 h-16 m-auto mb-4 flex items-center justify-center">
            <FontAwesomeIcon icon="comments" className="text-3xl text-white" />
          </div>
          <div>Private Members Forum</div>
        </div>
        <div className="md:w-1/3 mb-8 md:mb-0">
          <div className="bg-green rounded-full w-16 h-16 m-auto mb-4 flex items-center justify-center">
            <FontAwesomeIcon
              icon="calendar-alt"
              className="text-3xl text-white"
            />
          </div>
          <div>New Monthly Content</div>
        </div>
      </div>
    </div>
  );
};
Totals.propTypes = {
  className: PropTypes.string,
};

export default Totals;
