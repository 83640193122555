import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

import { IfOpen } from '../OpenFlags';
import Pricebox from '../Pricebox';

// FIXME: Right now, diresta is using a peters image.
const Callout = ({ className, variation, maker }) => (
  <StaticQuery
    query={graphql`
      query {
        samurai: file(relativePath: { eq: "samurai-lg.jpg" }) {
          data: childImageSharp {
            fixed(width: 1000, jpegProgressive: true, quality: 90) {
              src
            }
          }
        }
        peters: file(relativePath: { eq: "jon-peters/table-saw.jpg" }) {
          data: childImageSharp {
            fixed(width: 1000, jpegProgressive: true, quality: 90) {
              src
            }
          }
        }
        diresta: file(relativePath: { eq: "jon-peters/table-saw.jpg" }) {
          data: childImageSharp {
            fixed(width: 1000, jpegProgressive: true, quality: 90) {
              src
            }
          }
        }
        workbench: file(relativePath: { eq: "workbench.jpg" }) {
          data: childImageSharp {
            fixed(width: 1000, jpegProgressive: true, quality: 90) {
              src
            }
          }
        }
      }
    `}
    render={data => {
      const bgImg = data[variation].data.fixed.src;

      return (
        <div className={`relative bg-white ${className}`}>
          <div
            className="absolute pin-x pin-y"
            style={{
              backgroundImage: `url(${bgImg})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />
          <div
            className="opacity-75 absolute pin-x pin-y"
            style={{
              backgroundImage:
                'linear-gradient(to right, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.5))',
            }}
          />

          <div className="container mx-auto md:flex relative items-center opacity-100 px-8 py-24">
            <div className="text-white opacity-100 my-24 md:w-2/3 md:mr-12">
              <div className="text-4xl mb-6 font-bold">
                Take Your Woodworking to the Next Level
              </div>
              <div className="mb-4 text-base text-grey-light leading-normal opacity-100">
                Whether you are just starting out or a seasoned craftsman, let
                The Makers Mob show you [step-by-step] how to transform your
                woodworking skill and take it to the next level.
              </div>
              <div className="mb-4 text-base text-grey-light leading-normal opacity-100">
                While millions of viewers have been inspired by the Mobs videos
                on YouTube, members on the inside will learn the same techniques
                that have allowed them to become some of the most successful
                craftsman online.
              </div>
              <div className="mb-4 text-base text-grey-light leading-normal opacity-100">
                With millions YouTube subscribers, The Makers Mob team will
                teach you how to learn from your mistakes and engage in projects
                that will have you leaning towards our edges, so that you are
                constantly growing in your craft.
              </div>
            </div>
            <div className="md:w-1/3 md:text-right">
              <IfOpen>
                <Pricebox hidePrice={true} maker={maker} />
              </IfOpen>
            </div>
          </div>
        </div>
      );
    }}
  />
);

Callout.propTypes = {
  variation: PropTypes.oneOf(['samurai', 'peters', 'diresta', 'workbench']),
  maker: PropTypes.string,
};
Callout.defaultProps = {
  variation: 'samurai',
};

export default Callout;
