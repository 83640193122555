import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import Testimonial from './Testimonial';

const Testimonials = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          users: allFile(filter: { relativePath: { glob: "userPhotos/*" } }) {
            edges {
              node {
                childImageSharp {
                  fixed(width: 80, toFormat: JPG, quality: 100) {
                    originalName
                    src
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const userImgs = data.users.edges
          .map(e => e.node.childImageSharp)
          .reduce((acc, i) => {
            acc[i.fixed.originalName.split('.')[0]] = i.fixed.src;
            return acc;
          }, {});

        return (
          <React.Fragment>
            <div className="md:flex mx-2 md:mx-0 mb-6">
              <Testimonial
                className="md:mr-6 mb-4"
                name="Rick L."
                img={userImgs.user1}
              >
                You have inspired me. Thank you for helping me stretch into new
                areas of craftsmanship. You have inspired me to go farther in
                the creative process!!!
              </Testimonial>
              <Testimonial
                className="md:mr-6 mb-4"
                name="Donald C."
                img={userImgs.user2}
              >
                I just want to say thank you. I’ve been making wood chips & saw
                dust more in the last three weeks than I have in the last 6
                months and enjoying every minute.
              </Testimonial>
              <Testimonial
                name="Jonathan R."
                img={userImgs.user3}
                className="mb-4"
              >
                Thanks for your inspiration! A recent project my son and I did
                together! He really enjoyed helping dad make his own workbench!
                This is my favorite project ever!
              </Testimonial>
            </div>
            <div className="md:flex mx-2 md:mx-0">
              <Testimonial
                className="md:mr-6 mb-4"
                name="Hayden B."
                img={userImgs.user4}
              >
                I absolutely LOVE IT. First tool I've made! Then I did this
                cutting board with a walnut bowtie using the new plane!
              </Testimonial>
              <Testimonial
                className="md:mr-6 mb-4"
                name="Jeffery S."
                img={userImgs.user5}
              >
                You da man in all carpentry. Incorporating far eastern technique
                and design...beautiful workmanship!
              </Testimonial>
              <Testimonial className="mb-4" name="Andy T." img={userImgs.user6}>
                Inspired by your work. You inspired me to work hard at
                developing my skills and create some awesome projects along the
                way!
              </Testimonial>
            </div>
          </React.Fragment>
        );
      }}
    />
  );
};

export default Testimonials;
