import React, { Component } from 'react';
import PropTypes from 'prop-types';

// TODO: Signed up state.

class WaitlistFull extends Component {
  static propTypes = {
    makersName: PropTypes.string.isRequired,
    listId: PropTypes.string.isRequired,
    redirectUrl: PropTypes.string.isRequired,
    subscribed: PropTypes.bool,
  };

  static defaultProps = {
    subscribed: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      name: '',
    };
  }

  handleInputChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  renderForm() {
    const { name, email } = this.state;
    const { listId, redirectUrl, subscribed } = this.props;

    return (
      <div>
        <div className="text-2xl text-center mb-4">
          Makers Mob Private Community is Currently Closed to New Members
        </div>
        <form method="post" action="https://www.aweber.com/scripts/addlead.pl">
          <div className="md:flex mx-2 md:mx-0 justify-center">
            <div className="md:w-1/3 md:mr-4">
              <label
                htmlFor="name"
                className="uppercase text-grey-dark text-sm font-bold tracking-wide antialiased"
              >
                Name:
              </label>
              <input
                name="name"
                type="text"
                className="appearance-none rounded border border-grey-dark px-2 py-3 bg-white w-full mt-1"
                placeholder="Michael Scott"
                value={name}
                onChange={e => this.handleInputChange(e)}
                required={true}
              />
            </div>

            <div className="md:w-1/3 md:mr-4">
              <label
                htmlFor="email"
                className="uppercase text-grey-dark text-sm font-bold tracking-wide antialiased"
              >
                Email Address:
              </label>
              <input
                name="email"
                type="email"
                className="appearance-none rounded border border-grey-dark px-2 py-3 bg-white w-full mt-1"
                placeholder="michael.scott@dundermifflin.com"
                value={email}
                onChange={e => this.handleInputChange(e)}
                required={true}
              />
            </div>

            {/* Aweber hidden fields */}
            <input type="hidden" name="listname" value={listId} />
            <input type="hidden" name="redirect" value={redirectUrl} />
            {/*<input
  type="hidden"
  name="meta_adtracking"
  value="custom form"
/>
<input type="hidden" name="meta_message" value="1" />*/}
            <input type="hidden" name="meta_required" value="name,email" />
            <input type="hidden" name="meta_forward_vars" value="0" />

            <div className="md:w-1/3">
              <button
                className={`w-full border border-green appearance-none rounded py-3 mt-5 text-white uppercase antialiased text-lg font-bold tracking-wide ${
                  subscribed
                    ? 'bg-grey'
                    : 'bg-green hover:bg-green-light active:bg-green-dark'
                }`}
                type="submit"
                disabled={subscribed}
              >
                {subscribed ? 'Subscribed - Thank You!' : 'Get on the Waitlist'}
              </button>
              <div className="mt-2 text-sm text-grey-darker text-center">
                No SPAM, we promise. Unsubscribe Anytime.
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }

  renderThankYou() {
    const { makersName } = this.props;

    return (
      <div className="text-center">
        <h3 className="text-2xl text-center mb-4">Thanks for Signing Up!</h3>
        <div>
          You're on the Waitlist. Watch your email for upcoming information on{' '}
          {makersName}' courses in the Makers Mob.
        </div>
      </div>
    );
  }

  render() {
    const { subscribed } = this.props;

    return (
      <div className="bg-black text-white py-12">
        <div className="container mx-auto">
          {subscribed && this.renderThankYou()}
          {!subscribed && this.renderForm()}
        </div>
      </div>
    );
  }
}

export default WaitlistFull;
