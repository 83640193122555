import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

const noop = () => {};

const Maker = ({
  imgSrc,
  name,
  moniker,
  comingSoon = false,
  selected = false,
  onClick,
}) => (
  <div
    className={`md:flex-1 mr-4 text-center mb-6 md:mb-0 ${
      !selected && !comingSoon ? 'cursor-pointer' : ''
    }`}
    onClick={!comingSoon ? onClick : noop}
  >
    <div className="relative mb-3">
      {comingSoon && (
        <React.Fragment>
          <div className="text-white opacity-100 absolute pin z-10 text-sm uppercase antialiased tracking-wide mt-24">
            Coming Soon
            <br />
            {comingSoon}
          </div>
          <div className="bg-black opacity-75 absolute pin rounded z-0" />
        </React.Fragment>
      )}
      <img
        src={imgSrc}
        alt="Maker"
        className={`rounded block ${selected ? 'shadow-outline' : ''}`}
      />
    </div>
    <div className="text-md mb-2 text-grey-darker">{name}</div>
    {moniker && (
      <div className="uppercase text-xs text-grey-dark">{moniker}</div>
    )}
  </div>
);

class Makers extends React.Component {
  static propTypes = {
    defaultMaker: PropTypes.string,
    onMakerClick: PropTypes.func,
  };

  static defaultProps = {
    defaultMaker: '',
    onMakerClick: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedMaker: this.props.defaultMaker,
    };
  }

  componentDidUpdate(prevProps) {
    const { defaultMaker } = this.props;
    if (prevProps.defaultMaker !== defaultMaker) {
      this.setState({ selectedMaker: defaultMaker });
    }
  }

  render() {
    const { onMakerClick } = this.props;
    const { selectedMaker } = this.state;

    return (
      <StaticQuery
        query={graphql`
          query {
            samurai: file(relativePath: { eq: "makers/samurai2.jpg" }) {
              data: childImageSharp {
                fixed(width: 600, quality: 80) {
                  src
                }
              }
            }
            diresta: file(relativePath: { eq: "makers/diresta.jpg" }) {
              data: childImageSharp {
                fixed(width: 600, quality: 80) {
                  src
                }
              }
            }
            jonPeters: file(relativePath: { eq: "makers/jon-peters.jpg" }) {
              data: childImageSharp {
                fixed(width: 600, quality: 80) {
                  src
                }
              }
            }
            johnHeisz: file(relativePath: { eq: "makers/john-heisz.jpg" }) {
              data: childImageSharp {
                fixed(width: 600, quality: 80) {
                  src
                }
              }
            }
            liamHoffman: file(relativePath: { eq: "makers/liam-hoffman.jpg" }) {
              data: childImageSharp {
                fixed(width: 600, quality: 80) {
                  src
                }
              }
            }
            frankHowarth: file(
              relativePath: { eq: "makers/frank-howarth.jpg" }
            ) {
              data: childImageSharp {
                fixed(width: 600, quality: 80) {
                  src
                }
              }
            }
            neilPaskin: file(relativePath: { eq: "makers/neil-paskin.jpg" }) {
              data: childImageSharp {
                fixed(width: 600, quality: 80) {
                  src
                }
              }
            }
          }
        `}
        render={data => (
          <div className="container mx-auto">
            <div className="md:flex mx-2 md:mx-0 justify-between">
              <Maker
                imgSrc={data.samurai.data.fixed.src}
                name="Jesse De Geest"
                moniker="The Samurai Carpenter"
                selected={selectedMaker === 'samurai'}
                onClick={() => onMakerClick('samurai')}
              />
              <Maker
                imgSrc={data.diresta.data.fixed.src}
                name="Jimmy DiResta"
                // comingSoon={'Early 2019'}
                selected={selectedMaker === 'diresta'}
                onClick={() => onMakerClick('diresta')}
              />
              <Maker
                imgSrc={data.jonPeters.data.fixed.src}
                name="Jon Peters"
                // comingSoon={'Early 2019'}
                selected={selectedMaker === 'peters'}
                onClick={() => onMakerClick('peters')}
              />
              <Maker
                imgSrc={data.neilPaskin.data.fixed.src}
                name="Neil Paskin"
                selected={selectedMaker === 'neil'}
                onClick={() => onMakerClick('neil')}
              />
              <Maker
                imgSrc={data.frankHowarth.data.fixed.src}
                name="Frank Howarth"
                selected={selectedMaker === 'frank'}
                onClick={() => onMakerClick('frank')}
              />
              <Maker
                imgSrc={data.johnHeisz.data.fixed.src}
                name="John Heisz"
                selected={selectedMaker === 'ibuildit'}
                onClick={() => onMakerClick('ibuildit')}
              />
              <Maker
                imgSrc={data.liamHoffman.data.fixed.src}
                name="Liam Hoffman"
                selected={selectedMaker === 'liam'}
                onClick={() => onMakerClick('liam')}
              />
            </div>
          </div>
        )}
      />
    );
  }
}

export default Makers;
